<template>
  <div class="newsDetail">
    <div class="head">
      <div class="content">
        <h2>
          {{ list.length > 0 ? list[index].title : "" }}
        </h2>
        <h5 class="xbt">
          {{ list.length > 0 ? list[index].subtitle : "" }}
        </h5>
        <div class="spa">
          <span>
            {{
              list.length > 0 ? list[index].createTime.split(" ")[0] : ""
            }}</span
          ><span>{{
            list.length > 0 ? "来源：" + list[index].source : ""
          }}</span>
        </div>
      </div>
    </div>
    <div class="detail content">
      <div class="left" v-html="list.length > 0 ? list[index].content : ''">
        <!-- {{  }} -->
      </div>
      <div class="right">
        <div>
          <h4>公司新闻</h4>
          <div
            class="hit"
            v-for="(ids, index) in newLists"
            :key="'list' + ids.id"
            @click="jump('first', 1, index)"
          >
            <img :src="ids.thumbnail" alt="" />
            <div>
              <p>
                {{ ids.title }}
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="insuts">
          <h4>行业动态</h4>
          <div
            class="hit"
            v-for="(ids, index) in industryLists"
            :key="'insuts' + ids.id"
            @click="jump('second', 1, index)"
          >
            <img :src="ids.thumbnail" alt="" />
            <div>
              <p>
                {{ ids.title }}
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      type: 0,
      index: undefined,
      pageNo: undefined,
      list: [],
      newLists: [],
      industryLists: [],
      top: 0,
    };
  },
  watch: {
    $route: function () {
      let { type, index, pageNo } = this.$route.query;
      this.type = type == "first" ? 0 : 1;
      this.index = index;
      this.pageNo = pageNo;
      this.getData(this.pageNo, this.type);
    },
  },
  created() {},
  mounted() {
    let { type, index, pageNo } = this.$route.query;
    type == "insutTop"
      ? (this.top = 1) && (this.type = 1)
      : type == "top"
      ? (this.top = 1) && (this.type = 0)
      : type == "first"
      ? (this.top = 0) && (this.type = 0)
      : (this.top = 0) && (this.type = 1);
    this.index = index;
    this.pageNo = pageNo;
    // this.load();

    this.getRightData(1, 0);
    this.getRightData(1, 1);
    this.top == 1
      ? this.getTopData(this.pageNo, this.type)
      : this.getData(this.pageNo, this.type);
    console.log(434, this.list);
  },
  computed: {},
  methods: {
    // spArr(arr, num) {
    //   //arr是你要分割的数组，num是以几个为一组
    //   let newArr = []; //首先创建一个新的空数组。用来存放分割好的数组
    //   for (let i = 0; i < arr.length; ) {
    //     //注意：这里与for循环不太一样的是，没有i++
    //     newArr.push(arr.slice(i, (i += num)));
    //   }
    //   return newArr;
    // },
    jump(type, pageNo, index) {
      this.$router.push(
        `/newsDetail?type=${type}&&pageNo=${pageNo}&&index=${index}`
      );
    },
    getData(pageNo, type) {
      axios
        .get(
          `http://101.37.125.135:8080/jeecg-boot/manage-api/v1/news/list?pageNo=${
            pageNo || 1
          }&pageSize=5&category=${type || 0}&top=0&frame=1`
        )
        .then((res) => {
          console.log(123, this.list);
          this.list = res.data.result.records;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTopData(pageNo, type) {
      axios
        .get(
          `http://101.37.125.135:8080/jeecg-boot/manage-api/v1/news/list?pageNo=${
            pageNo || 1
          }&pageSize=5&category=${type || 0}&top=1&frame=1`
        )
        .then((res) => {
          console.log(123, this.list);
          this.list = res.data.result.records;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRightData(pageNo, type) {
      axios
        .get(
          `http://101.37.125.135:8080/jeecg-boot/manage-api/v1/news/list?pageNo=${
            pageNo || 1
          }&pageSize=5&category=${type || 0}&frame=1&top=0`
        )
        .then((res) => {
          console.log(123, res);
          type == 0
            ? (this.newLists = res.data.result.records)
            : (this.industryLists = res.data.result.records);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.newsDetail {
  // height: 400px;
  padding-top: 64px;

  .head {
    height: 200px;
    width: 100vw;
    background-color: #f8f8f8;
    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      & > h5 {
        margin-top: 0;
        // color: #9fb5cd;
      }
      .spa {
        span {
          margin-right: 2vw;
        }
      }
    }
  }
  .detail {
    display: flex;
    justify-content: start;
    .left {
      width: 750px;
      padding-top: 20px;
      margin-right: 100px;
      font-size: 16px;
      overflow: hidden;
      img {
        width: 100%;
      }
      & > div {
        margin-bottom: 20px;
      }
    }
    .right {
      width: 350px;
      padding-left: 40px;
      border-left: 1px solid #e2e2e2;
      .hit {
        width: 100%;
        height: 100px;
        display: flex;
        margin-bottom: 20px;
        img {
          flex-shrink: 0;
          width: 160px;
          height: 100px;
          margin-right: 20px;
        }
        // height: 100px;
        div {
          height: 100%;

          border-bottom: 1px solid #ccc;
          overflow: hidden;

          p {
            margin-top: 0px;
            line-height: 1.2rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 4.8;
            font-size: 14px;
            -webkit-line-clamp: 4;
          }
        }
      }
    }
  }
}
</style>
