<template>
  <div class="index">
    <Head></Head>
    <router-view />
    <Foot></Foot>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Foot from "@/components/Foot.vue";

export default {
  name: "index",
  components: {
    Head,
    Foot,
  },
  mounted() {},
  watch: {
    $route: function (e) {
      console.log(444, e);
      window.scrollTo(0, 0);

      // this.scrollTop =0
    },
  },
};
</script>
<style scoped>
.index{
  width: 100%;
}
</style>
