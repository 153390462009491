<template>
  <div class="content help">
    <div class="navigation">
      <span class="childStore" @click="store">海鲜盛宴商城</span
      ><span> > 帮助中心</span>
    </div>
    <div class="cont">
      <div class="left">
        <div class="head">常见问题</div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="账户问题" name="1">
            <div
              class="coig"
              v-for="(item, index) in account"
              :key="item.id"
              @click="changeImg('account', index)"
            >
              {{ item.title }}
            </div>
          </el-collapse-item>
          <el-collapse-item title="订购问题" name="2">
            <div
              class="coig"
              v-for="(item, index) in order"
              :key="item.id"
              @click="changeImg('order', index)"
            >
              {{ item.title }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="right">
        <div class="head">{{ title }}</div>
        <div class="gut">
          <img class="imgs" :src="imgurl" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/assets/a.js";
let { helps } = config;
let { account, order } = helps;
export default {
  data() {
    return {
      activeName: 1,
      account,
      order,
      flag: "account",
      index: 0,
      imgurl: "",
      title: "",
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    store() {
      this.$router.push("/store");
    },
    changeImg(flag, index) {
      // this.flag=flag
      // this.index=index
      console.log(this[flag][index].imgUrl);
      this.imgurl = this[flag][index].imgUrl;
      this.title = this[flag][index].title;
    },
  },
  watch: {
    imgurl: function (e) {
      // require(e)
    },
  },
};
</script>
<style lang="less" >
.help {
  margin-top: 80px;
  .navigation {
    margin: 3vh 0;
    .childStore:hover {
      cursor: pointer;
    }
  }
  .cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    .left {
      width: 20%;
      // border: 1px solid red;
      .head {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        font-weight: 550;
        color: #fff;
        background-color: #178cceff;
        letter-spacing: 0.25rem;
      }
      .el-collapse-item__header {
        background-color: #e4e4e4ff;
        border-block-color: #c8c8c8ff;
        padding-left: 1vw;
        font-size: 1.3rem;
      }
      .el-collapse-item__content {
        padding: 0;
      }
      .coig {
        width: 100%;
        line-height: 3rem;
        border: 1px solid #c8c8c8ff;
        border-top: none;
        color: #707070ff;
      }
    }
    .right {
      width: 78%;
      // border: 1px solid red;

      .head {
        background-color: #e4e4e4ff;
        padding-left: 2%;

        width: 100%;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.1rem;
        font-weight: 560;
        letter-spacing: 1.5px;
      }
      .gut {
        margin-top: 3vh;
        padding-left: 2%;
        width: 100%;
        min-height: 100px;
        .imgs {
          width: 98%;
        }
      }
    }
  }
}
</style>
