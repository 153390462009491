<template>
  <div class="about">
    <div class="banner">
      <img src="@/assets/images/about/aboutUs.jpg" alt="" />
    </div>
    <div class="content introduction">
      <div class="titles" ref="BRAND INTRODUCTION">
        <div class="head">品牌介绍</div>
        <div class="cont"></div>
        <div class="foot">BRAND INTRODUCTION</div>
      </div>
      <div class="detail">
        <div class="left">
          <img src="@/assets/images/about/introdu2.jpg" alt="" />
        </div>
        <div class="right">
          <div>
            <h3 class="xbt">专业进口海产领鲜服务商</h3>
            <p class="zwsize">
              1994年起始于美国西雅图，历经30年，目前已发展成为集全球采购，加工、研发、品牌、销售于一体的全品类专业海产领鲜服务商。
            </p>
            <p class="zwsize">
              公司主要生产、加工和销售各类野生鳕鱼（银鳕、黒鳕、真鳕、狭鳕、黑线鳕等）、三文鱼、比目鱼、鲽鱼、红鱼、黑虎虾、蓝虾、香蕉虾、褐虎虾、龙虾、帝王蟹、珍宝蟹、带子、青口贝等上百种世界高端海产品，并根据客户偏好提供款式精美的海鲜礼盒，产品深受市场和消费者的喜爱。
            </p>
          </div>
          <div>
            <h3 class="xbt">
              Leader and professional full-service provider for imported seafood
            </h3>
            <p class="zwsize">
              Originally established in Seattle, USA, Ocean Gala has been in the
              seafood business for over 30 years, and has developed into a
              leader and professional full-service provider for imported
              seafood, integrating with production, research & development,
              branding, marketing and sales. Our products are currently sold to
              North America and EU, and in China Ocean Gala has become a leading
              brand for imported seafood.
            </p>
            <p class="zwsize">
              Our products include various wild finfish (Toothfish, Black Cod,
              Pacific/Atlantic Cod, Pollock and Haddock), Salmon, Halibut, Sole,
              Redfish, Black Tiger Shrimp, Blue Shrimp, Banana Prawn, Brown
              Tiger Prawn, Lobster, King Crab, Dungeness Crab, Scallop, Blue
              Mussel and others, total over 100 premium seafood sourced
              globally. We also supply customized seafood gift boxes that are
              very popular with consumers.
            </p>
          </div>
        </div>
      </div>

      <div class="titles" ref="OUR BRAND">
        <div class="head">品牌解析</div>
        <div class="cont"></div>
        <div class="foot">OUR BRAND</div>
      </div>
      <img class="OurBrand" src="@/assets/images/about/OurBrand.png" alt="" />
      <div class="titles" ref="RRSOURCE ADVANTAGE">
        <div class="head">资源优势</div>
        <div class="cont"></div>
        <div class="foot">RRSOURCE ADVANTAGE</div>
      </div>
      <!-- <div :class="{exp:true,active:falg}" @click="cli"></div>
      <div :class="{exp:true,active:!falg}" @click="cli"></div> -->
    </div>
    <div class="resource">
      <div class="content resourceFa">
        <div :class="{ active: fir }" @mouseover="jitchange('fir')">
          <div class="left">
            <span>Resources Advantage</span>
            <span>产地优势</span>
          </div>
          <div class="right">
            <span
              >“Ocean
              gala”的产品均来自天然深海水域。良好的天然环境造就了其海鲜肉质精细、味道鲜美、营养丰富的特点。</span
            >
            <p>
              Ocean gala products are from unpolluted pristine wild environment,
              possess delicate texture and flavor, and are rich in heathy
              nutrients.
            </p>
          </div>
        </div>
        <div :class="{ active: sec }" @mouseover="jitchange('sec')">
          <div class="left">
            <span>R&D Advantage</span>
            <span>技术优势</span>
          </div>
          <div class="right">
            <span
              >2010年与中国水产科学研究院黄海水产研究所共同成立“海洋生物资源开发利用技术创新与产业化中心”，通过“产学研”实现水产资源的深度开发与规模产业化运作；2010年6月成立海洋生物资源高值化技术中心，并举行首次研讨会。</span
            >
            <p>
              Established Biotechnology Research Center for Under-Utilized
              Marine Resources in partnership with Yellow Sea Fisheries Research
              Institute of Chinese Academy of Fishery Sciences, and Marine
              Bio-Resources Value-Added Technology Center, both in 2010.
            </p>
          </div>
        </div>
        <div :class="{ active: thr }" @mouseover="jitchange('thr')">
          <div class="left">
            <span>Service Advantage</span>
            <span>服务优势</span>
          </div>
          <div class="right">
            <span
              >公司注重研发产品，联合渠道商、餐饮采购商，研发适合各渠道通路的产品，并会配合新品上市，提供各种形式的营销推广活动。</span
            >
            <p>
              Market driven R&D effort in partnership with customers to develop
              new products, and to provide extensive marketing activities to
              promote new products.
            </p>
          </div>
        </div>
        <div :class="{ active: fou }" @mouseover="jitchange('fou')">
          <div class="left">
            <span>Quality Advantage</span>
            <span>质量优势</span>
          </div>
          <div class="right">
            <span
              >集团在质保技术、团队及各项工艺技术检测设备等方面进行了大量的投入。</span
            >
            <span>在原料的采购方面也做到了严格的内外把控。</span>
            <span>
              内部：加工工厂全面贯彻执行全球认证的HACCP计划（危害分析与关键控制点），并建立了良好的内部追溯系统和召回系统。
            </span>
            <span>
              外部：建立了产品可追溯性，即从捕捞船（原料）到消费者市场（成品）的保管链。采购的原料随身附有捕捞证书和产地证，及将原料鱼运往中国的集装箱船舶信息，加工时的冷库信息，加工后的产品类型及数量，何时何人生产的日期和车间信息，将货物出售给客户的具体信息等等都一一记录在案，以备查阅。
            </span>
            <span>
              Always quality conscious we have invested heavily in QA team
              building, testing technologies and equipment, and raw material
              inspection process. We strictly follow the HACCP plan in
              production, and have excellent traceability and recall systems in
              place. Our full traceability system records information along
              every step from the harvesting vessel for the raw material to the
              end customer we sold the finished products to, including various
              certificates (health, catch and origin) for the raw materials,
              vessel name and container number for the shipment from the
              supplier to our warehouse, warehouse storage and in/out
              information, dates, workshop and worker shifts information for
              production, and finally details of sales and shipment to the end
              customer.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="content network">
      <div class="titles" ref="THE ASLES NETWORK">
        <div class="head">销售网络</div>
        <div class="cont"></div>
        <div class="foot">THE ASLES NETWORK</div>
      </div>
      <h4 class="xbt">全球采购和销售</h4>
      <img src="@/assets/images/about/global.png" alt="" />
      <h4 class="xbt">国内营销</h4>
      <img src="@/assets/images/about/home.png" alt="" />
    </div>
    <div class="content development">
      <div class="culture">
        <div class="titles" ref="CORPORATE CULTURE">
          <div class="head">企业文化</div>
          <div class="cont"></div>
          <div class="foot">CORPORATE CULTURE</div>
        </div>
        <div class="detail">
          <div class="left">
            <div>
              <p class="head">企业愿景 <span>Our Vision</span></p>
              <p class="cont">树立中国民族品牌全球知名度，</p>
              <p class="cont">建立从资源到消费者的完整产业链。</p>
              <!-- <p class="head">Our Vision</p>-->
              <p class="cont">
                To establish a world renowned Chinese brand. To build an
                enterprise vertically integrated from resources to consumer
                markets.
              </p>
              <!-- <p class="cont"></p>  -->
            </div>
            <div>
              <p class="head">企业精神 <span>Our Philosophy</span></p>
              <p class="cont">
                感恩为先 &nbsp;责任为重&nbsp;真诚为道 &nbsp;快乐为终
              </p>
              <p class="cont">To be grateful, responsible, honest and happy</p>
            </div>
            <div>
              <p class="head">核心价值观 <span>Our Core Value</span></p>
              <p class="cont">大海凝聚智慧 &nbsp;事实缔造辉煌</p>
              <p class="cont">
                From the sea comes wisdom. Out of truth there is brilliance.
              </p>
            </div>
          </div>
          <div class="right">
            <img src="@/assets/images/about/chuan.png" alt="" />
          </div>
        </div>
      </div>
      <div class="honor">
        <div class="titles" ref="Recognitions">
          <div class="head">企业荣誉</div>
          <div class="cont"></div>
          <div class="foot">Recognitions</div>
        </div>
        <div class="recognitionsList">
          <div class="left">
            <div class="head">
              公司荣誉
              <p>Honorary</p>
            </div>
            <div class="liseadad">
              <div>
                <p>被华润授予“优秀合作伙伴”奖</p>
                <p>Best Partner from Ole'</p>
              </div>
              <div>
                <p>荣获MSC “中国可持续海产市场突出贡献奖”</p>
                <p>
                  Outstanding contributor for Chinese sustainable seafood from
                  MSC
                </p>
              </div>
              <div>
                <p>荣获CCFA最佳合作伙伴奖</p>
                <p>Best Partner from CCFA</p>
              </div>
              <div>
                <p>上合青岛峰会海产品供应商, 被授予“食品安全保障企业”</p>
                <p>
                  Supplier for The Shanghai Cooperation Organization Summit in
                  Qingdao. Accredited as Food Safety Guaranteed Enterprise.
                </p>
              </div>
              <div>
                <p>荣获“山东省食品行业优秀龙头企业”</p>
                <p>Accredited as Leading Food Enterprise of Shandong</p>
              </div>
              <div>
                <p>Ocean Gala被评为“最受网友喜爱海产品牌”</p>
                <p>
                  Ocean Gala has been selected as Most Favorite Seafood Brand by
                  online consumers
                </p>
              </div>
              <div>
                <p>
                  公司获评“最受网友喜爱的十大知名海鲜进口商”、“十大知名海鲜进口商”
                  上榜企业、“中国十大最具品牌影响力海产企业”
                </p>
                <p>
                  Company has been selected onto the lists of “Top 10 Most
                  Favorite Seafood Importers by Online Consumer”, “Top 10 Most
                  Famous Seafood Importers”, and “China’s Top 10 Most
                  Influential Seafood Brands”.
                </p>
              </div>
            </div>
          </div>
          <div class="left">
            <div class="head">
              创始人荣誉
              <p>Founder's Honorary</p>
            </div>
            <div class="liseadad">
              <div>
                <p>多次入选五年一次的“全球100位海产行业最具影响力高管”榜单</p>
                <p>
                  Selected multiple times onto the list of “Most Influential
                  Managers in Global Seafood Industry” that’s announced every 5
                  years.
                </p>
              </div>
              <div>
                <p>获选“中国海产十大最具影响力年度人物“</p>
                <p>
                  Selected onto the list of “10 Most Influential Person in
                  Chinese Seafood Industry”
                </p>
              </div>
              <div>
                <p>
                  习近平主席首次访问美国，作为在美华侨华人水产界唯一代表获邀出席招待晚宴并与习近平主席及华侨华人代表合影。
                </p>
                <p>
                  As the only Chinese representative from seafood industry,
                  participated the reception banquet for President Xi Jinping
                  when he visited USA for the 1st time.
                </p>
              </div>
              <div>
                <p>
                  作为全球水产界唯一代表受邀出席在新加坡举办的全球农业投资峰会（Global
                  AgInvesting
                  Asia），并作了“中国水产业的投资机会”的主题发言，获得了全球农业投资界的高度好评。
                </p>
                <p>
                  As the only representative from global seafood industry,
                  participated the Global AgInvesting Asia in Singapore, and
                  made a highly perceived presentation on investment
                  opportunities in Chinese seafood industry.
                </p>
              </div>
              <div>
                <p>获聘为中国海洋大学客座教授。</p>
                <p>Honorary professor of Ocean University of China</p>
              </div>
              <div>
                <p>
                  应邀参加了中国乌鲁木齐举办的中国西方经济首脑论坛，同时受到与会的第42届美国总统克林顿先生的接见。
                </p>
                <p>
                  Participated the China and Western Economy Leader Summit held
                  in Urumqi, China, and met the 42nd USA president Mr. Bill
                  Clinton.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 废弃的图文方式 留做备用 -->
        <!-- <div class="recog">
          <div class="left">
            <transition>
              <img v-show="!flag" :src="recognitions[num].leftImg" alt="" />
            </transition>
          </div>
          <div class="right">
            <div class="top">
              <transition>
                <div v-show="!flag" class="fas">
                  <div class="head">{{ recognitions[num].rightText.head }}</div>
                  <div>
                    <p class="contentCn">
                      {{ recognitions[num].rightText.contentCn }}
                    </p>
                    <p class="contentEn">
                      {{ recognitions[num].rightText.contentEn }}
                    </p>
                  </div>
                </div>
              </transition>
            </div>
            <div class="botom">
              <div @click="() => changeShow(-1)" class="pre"></div>

              <div @click="() => changeShow(1)" class="next"></div>
            </div>
          </div>
        </div> -->
        <!-- <div class="scorl">
          <div ref="scorl">
            <div
              class="scorlItem"
              ref="item"
              v-for="item in lines"
              :key="item.id"
            >
              <div class="time">{{ item.time }}</div>
              <div
                class="neir"
                v-for="(it, index) in item.neir"
                :key="'neir_' + index"
              >
                <div class="cn">
                  {{ it.cn }}
                </div>
                <div class="en">
                  {{ it.en }}
                </div>
              </div>
            </div>
          </div>
          <div class="left" @click="back"></div>
          <div class="right" @click="pro"></div>
        </div> -->
        <!-- <h4 class="xbt gx">社会贡献 Contributions to Communities</h4>
        <div class="jhuy">
          <div>
            <span>
              2012年、2013年和2014年，美国张氏集团与波音、微软、
              Tiffany、汇丰银行Mercedes-Benz等世界知名品牌企业一同成为华盛顿州香港商会中国新年活动的主赞助商。</span
            >
            <span
              >2012. 2013 and 2014 Chang International, along with world
              renowned companies such as Boeing, Microsoft, Tiffany&Co, HSBC.
              Mercedes-benz and others was one of the main sponsors for the
              Chinese New Year Party event organized by the Hong Kong Business
              Associations of Washington.</span
            >
          </div>
          <div>
            <span>
              集团多次为即墨大信镇教育系统捐款近百万元，被评为“即墨市尊师重教先进单位“。</span
            >
            <span>
              Chang International has made multiple donations with total amount
              close to one million Yuan to the education system of Daxin town in
              Jimo of Qingdao, and was named as Jimo's Leading Enterprise of
              Respecting Teachers and Educations</span
            >
          </div>
          <div>
            <span>
              22006年初，参与由青岛电视台《生命树》栏目发起的“捐助贫困大学生回家过年”活动。
            </span>
            <span
              >At the beginning of 2006 Chang International participated in the
              Life Tree program organized by Qingdao Television to help college
              students in needs to go back to their hometown for the Chinese New
              Year holiday.</span
            >
          </div>
          <div>
            <span>
              集团董事长 Jerry
              Chang先生作为校友捐赠五十万元(浙江省定海第一中学)。</span
            >
            <span>
              Chairman Mr. Jerry Chang donated 500.000 Yuan to his alma mater-
              Zhejiang Dinghai No. 1 High School.</span
            >
          </div>
        </div> -->
      </div>
      <div class="titles" ref="CORPORATE HONOR AND SOCIAL RESPONSIBILITY">
        <div class="head">企业资质和认证</div>
        <div class="cont"></div>
        <div class="foot">GROUP CREDENTIALS AND CERTIFICATIONS</div>
      </div>
      <div>
        <p>
          Group Credentials and Certifications
          企业资质和认证国际组织GAA理事会常务理事、NWFA会员及GROUNDFISH
          FORUM会员，并取得美国、加拿大和欧盟注册。同时获得HACCP证书、MSC证书、ASC证书、BAP证书、BRC证书、IOS9001证书、三同企业认证资格。
        </p>
        <p>
          Executive member of Global Aquaculture Alliance (GAA). Member of
          Northwest Fishery Association (NWFA) and Groundfish Forum. Approved
          for export to USA, Canada and EU. HACCP, BRC, MSC, ASC, BAP and
          IOS9001 certified. Certified by the Chinese “Three Synchronized”
          standard.
        </p>
      </div>
      <transition>
        <div class="group" ref="wings">
          <div class="tops">
            <img
              src="../assets/images/about/haccp.png"
              :class="{ topps: isInViewport }"
              alt=""
            />
            <div :class="{ top: true, botm: isInViewport }">
              <h3>HACCP证书</h3>
              <p>
                HACCP（Hazard Analysis and Critical Control Point，
                危害分析和关键控制点）
              </p>
            </div>
            <div :class="{ botm: isInViewport }">
              <h3>HACCP Certificate</h3>
              <p>
                HACCP (Hazard Analysis and Critical Control Point) is an
                internationally recognized method of identifying and analyzing
                food safety related risk that is essential for managing food
                safety programs.
              </p>
            </div>
          </div>
          <div class="tops">
            <img
              src="../assets/images/about/st.png"
              :class="{ topps: isInViewport }"
              alt=""
            />
            <div :class="{ top: true, botm: isInViewport }">
              <h3>三同企业认证资格</h3>
              <p>出口产品内外销“同线同标同质”</p>
            </div>
            <div :class="{ botm: isInViewport }">
              <h3>Certified by the Chinese “Three Synchronized” standard.</h3>
              <p>
                Certification for using the same production line and same
                quality standard for both products intended for Chinese market
                and export.
              </p>
            </div>
          </div>
          <div class="tops">
            <img
              src="../assets/images/about/ios.png"
              :class="{ topps: isInViewport }"
              alt=""
            />
            <div :class="{ top: true, botm: isInViewport }">
              <h3>IOS9001证书</h3>
              <p>
                ISO9001用于证实组织具有提供满足顾客要求和适用法规要求的产品的能力
              </p>
            </div>
            <div :class="{ botm: isInViewport }">
              <h3>ISO9001 Certificate</h3>
              <p>
                ISO 9001 is an international standard that specifies
                requirements for a quality management system (QMS).
              </p>
            </div>
          </div>
          <div class="bottoms">
            <img
              src="../assets/images/about/bap.png"
              :class="{ topps: isInViewport }"
              alt=""
            />
            <div :class="{ top: true, botm: isInViewport }">
              <h3>BAP证书</h3>
              <p>
                最佳水产养殖规范（BAP）标准涵盖水产养殖设施的环境和社会责任、动物福利、食品安全和可追溯性。
              </p>
            </div>
            <div :class="{ botm: isInViewport }">
              <h3>BAP Certificate</h3>
              <p>
                BAP (Best Aquaculture Practices) is an international
                certification program that addresses key areas of sustainability
                for aquaculture production – environmental, social, food safety,
                animal health & welfare, and traceability.
              </p>
            </div>
          </div>
          <div class="bottoms">
            <img
              src="../assets/images/about/asc.png"
              :class="{ topps: isInViewport }"
              alt=""
            />
            <div :class="{ top: true, botm: isInViewport }">
              <h3>ASC证书</h3>
              <p>ASC的宗旨是在全球范围内推动传统水产养殖业的可持续发展</p>
            </div>
            <div :class="{ botm: isInViewport }">
              <h3>ASC Certificate</h3>
              <p>
                ASC (Aquaculture Stewardship Council) is an international
                recognized standard that establishes protocol on farmed seafood
                while ensuring sustainable aquaculture.
              </p>
            </div>
          </div>
          <div class="bottoms">
            <img
              src="../assets/images/about/msc.png"
              :class="{ topps: isInViewport }"
              alt=""
            />
            <div :class="{ top: true, botm: isInViewport }">
              <h3>MSC证书</h3>
              <p>
                MSC的宗旨是为了推动可持续渔业的发展以保障现在和未来的海产品供应。
              </p>
            </div>
            <div :class="{ botm: isInViewport }">
              <h3>MSC Certificate</h3>
              <p>
                MSC (Marine Stewardship Council) is an international recognized
                standard that recognizes and rewards efforts to protect oceans
                and safeguard seafood supplies for the future.
              </p>
            </div>
          </div>
          <div class="tops">
            <img
              src="../assets/images/about/brc.png"
              :class="{ topps: isInViewport }"
              alt=""
            />
            <div :class="{ top: true, botm: isInViewport }">
              <h3>BRC证书</h3>
              <p>
                在食品安全方面，建立零售商、供应商认同的一套核心安全预期要求。
              </p>
            </div>
            <div :class="{ botm: isInViewport }">
              <h3>BRC Certificate</h3>
              <p>
                BRC (British Retail Consortium) is an international food safety
                management standard, and is one of the GFSI (Global Food Safety
                Initiative) recognized certification schemes, adopted by most
                food manufacturers and retailers all around the world.
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import config from "@/assets/a.js";
let { lines, recognitions } = config;
export default {
  data() {
    return {
      lines,
      fir: true,
      sec: false,
      thr: false,
      fou: false,
      so: 0,
      allScorl: 0,
      go: 0,
      item: 0,
      flag: false,
      num: 0,
      recognitions,
      isInViewport: false,
    };
  },
  created() {
    this.listenerFunction();
  },
  destroyed() {
    this.stopListenerFunction();
  },
  mounted() {
    var a = this.$route.params.jumpRef;
    console.log(444444444, this.$route.params.jumpRef);
    a &&
      window.scrollTo({
        top: this.$refs[a].getBoundingClientRect().top - 70,
        behavior: "smooth",
      });
    // this.allScorl = this.$refs.scorl.scrollWidth;
    // this.item = this.$refs.item[0].clientWidth;
    console.log(978, this.item);
  },
  computed: {},
  methods: {
    jitchange(e) {
      switch (e) {
        case "fir":
          this[e] = true;
          this.sec = false;
          this.thr = false;
          this.fou = false;
          break;
        case "sec":
          this[e] = true;
          this.fir = false;
          this.thr = false;
          this.fou = false;
          break;
        case "thr":
          this[e] = true;
          this.fir = false;
          this.sec = false;
          this.fou = false;
          break;
        case "fou":
          this[e] = true;
          this.fir = false;
          this.sec = false;
          this.thr = false;
          break;
        default:
          break;
      }
    },
    pro() {
      if (this.so * this.item >= this.allScorl) {
        this.so = this.allScorl / this.item - 1;
        return;
      } else {
        this.so = this.so + 1;
        let stop = setInterval(() => {
          this.go = this.go + 1;
          console.log(this.allScorl, this.so, this.go);
          this.go >= this.item * this.so
            ? clearInterval(stop)
            : this.$refs.scorl.scrollTo(this.go, 0);
        }, 0.1);
      }
    },
    back() {
      if (this.so <= 0) {
        this.so = 0;
        this.go = 0;
        this.$refs.scorl.scrollTo(this.go, 0);
        return;
      } else {
        console.log(
          this.so * this.item == this.allScorl,
          this.so * this.item,
          this.allScorl
        );

        this.so * this.item == this.allScorl
          ? this.$refs.scorl.scrollTo(this.so - 2 * this.item, 0) &&
            (this.so = this.so - 3)
          : (this.so = this.so - 1);
        let stop = setInterval(() => {
          this.go = this.go - 1;
          this.go <= this.so * this.item
            ? clearInterval(stop)
            : this.$refs.scorl.scrollTo(this.go, 0);
        }, 0.1);
      }
    },
    changeShow(e) {
      this.flag = !this.flag;
      setTimeout(() => {
        this.flag = !this.flag;
        this.num == this.recognitions.length - 1 && e == 1
          ? (this.num = 0)
          : this.num == 0 && e == -1
          ? (this.num = this.recognitions.length - 1)
          : (this.num = this.num + e);
      }, 800);
      console.log(323, this.num, this.recognitions[this.num]);
    },
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    stopListenerFunction() {
      document.removeEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      if (this.hasScrolled) return;
      const topDistance = this.$refs.wings.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const elemHeight = this.$refs.wings.clientHeight;
      this.isInViewport =
        topDistance <= windowHeight && topDistance >= 0 - elemHeight;
      console.log(323, this.isInViewport);

      // this.hasScrolled = true;
    },
  },
};
</script>
<style lang="less" scoped>
.about {
  padding-top: 64px;

  .banner {
    width: 100%;
    min-height: calc(100vw / 3.2);

    img {
      width: 100%;
      min-height: calc(100vw / 3.2);
      vertical-align: top;
    }
  }
  .titles {
    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .head {
      font-size: 1.9rem;
      color: #178cceff;
    }
    .cont {
      width: 3%;
      margin-top: 1%;
      margin-bottom: 1%;
      border-bottom: 2px solid #178cceff;
    }
    .foot {
      color: #707070ff;
      font-size: 1.1rem;
      margin-bottom: 30px;
    }
  }
  .introduction {
    font-size: 1rem;

    .detail {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 650px;
      margin-bottom: 3vh;

      .left {
        flex-shrink: 0;
        width: 500px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        flex-shrink: 0;
        width: 680px;
        height: 100%;
        // overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        & > div:nth-child(1) {
          margin-bottom: 30px;
        }
        h3 {
          margin: 0 0 5px;
        }
        p {
          text-align: justify;
          margin: 0 0 5px;
        }
      }
    }
    .OurBrand {
      width: 88%;
      margin: 0 6%;
      min-height: 340px;
      // margin-bottom: 7vh;
    }

    //  .exp {
    //    width: 150px;
    //    height: 150px;
    //    background: red;

    //  }
    //  .active{
    //     width:500px;
    //     transition-property: width;
    //    transition-duration: 1s;
    //  }
  }
  .resource {
    width: 100%;
    height: 780px;
    background: url(../assets/images/about/resource.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom: 5vh;
    .resourceFa {
      height: 100%;
      display: flex;
      justify-content: space-around;
      & > div {
        width: 100px;
        height: 100%;
        display: flex;
        overflow: hidden;
        background: #178cce;
        opacity: 0.8;
        transition-property: width;
        transition-duration: 1s;

        .left {
          color: #fff;
          font-size: 1.3rem;
          width: 100px;
          flex-shrink: 0;
          writing-mode: vertical-lr;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            display: inline-block;
            height: 35%;
            &:nth-child(2) {
              font-size: 1.43rem;
              font-weight: 700;
              letter-spacing: 0.8rem;
            }
          }
        }
        .right {
          color: #fff;
          width: 550px;
          font-size: 19px;
          line-height: 28px;
        }
      }
      .active {
        width: 700px;
        transition-property: width;
        align-items: start;
        transition-duration: 1.5s;
        padding: 50px 50px 0 0;

        .left {
          span {
            display: inline-block;
            height: 100% !important;
          }
        }
        .right {
          span {
            display: inline-block;
            margin: 0 0 7px 0;
          }
        }
      }
    }
  }
  .network {
    margin-bottom: 5vh;
    h4 {
      text-align: center;
    }
    img {
      width: 100%;
      min-height: 634px;
    }
  }
  .development {
    margin-bottom: 5vh;
    img {
      width: 100%;
    }
    .culture {
      width: 100%;
      margin-bottom: 5vh;
      .detail {
        display: flex;
        justify-content: space-between;
        .left {
          // width: calc(1200px * 0.4);
          // height: 200px;
          width: 45%;
          font-size: 20px;
          & > div {
            margin-bottom: 30px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          p {
            margin: 0;
          }
          .head {
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #252525;
            margin-bottom: 10px;
            span {
              font-size: 16px;
              font-weight: 500;
              color: #707070ff;
            }
          }
          .cont {
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #707070;
            line-height: 30px;
          }
        }
        .right {
          // width: calc(1200px * 0.6);
          img {
            width: 600px;
            height: 470px;
          }
        }
      }
    }
    .honor {
      margin-bottom: 5vh;
      h4 {
        text-align: center;
      }
      // .bannes {
      //   width: 100%;
      //   height: calc(1200px / 4.6);
      //   display: flex;
      //   padding-top: 8%;
      //   justify-content: center;
      //   align-items: center;
      //   background: url(../assets/images/about/qyBanner.png) no-repeat;
      //   background-size: 100% 100%;
      //   h3 {
      //     color: #fff;
      //   }
      // }
      .scorl {
        position: relative;
        width: 1200px;
        height: 360px;

        & > div:nth-child(1) {
          width: 100%;
          height: 100%;
          overflow-x: scroll;
          display: flex;
          flex-wrap: nowrap;
          background-color: rgb(248, 248, 248);
          .scorlItem {
            flex-shrink: 0;
            padding-right: 20px;
            margin-top: 50px;
            width: 400px;
            height: 260px;
            & > div {
              margin-top: 10px;
            }
            .time {
              border-bottom: 2px solid rgb(230, 230, 230);
              line-height: 30px;
              font-weight: 700;
            }
          }
          img {
            height: 99.9999%;
            width: calc(1200px / 1.86 * 7);
            vertical: top;
          }
        }
        .left {
          width: 30px;
          height: 30px;
          background: url(../assets/images/about/back.png) no-repeat;
          background-size: 100% 100%;
          position: absolute;
          top: 50%;
          left: 2%;
          &:hover {
            cursor: pointer;
          }
        }
        .right {
          width: 30px;
          height: 30px;
          background: url(../assets/images/about/pro.png) no-repeat;
          background-size: 100% 100%;
          position: absolute;
          top: 50%;
          right: 2%;
          &:hover {
            cursor: pointer;
          }
        }
      }
      & > img {
        width: 100%;
      }
      .gx {
        margin: 40px 0 40px;
      }
      .jhuy {
        // border: 1px solid #178cce;
        height: 632px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        & > div {
          flex-shrink: 0;
          width: 600px;
          height: 316px;
          font-size: 16px;
          padding: 40px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 26px;
          &:first-child,
          &:last-child {
            color: #fff;
            background-color: #178cce;
          }
          span:first-child {
            display: inline-block;
            margin-bottom: 20px;
          }
        }
      }
    }
    .group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 50px;
      .tops {
        width: 32%;
        height: calc((1200px * 32%) / 0.53);
        background-color: #f5f5f5;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 20px 24px;
        box-sizing: border-box;
        overflow: hidden;
        margin-bottom: 30px;

        .top {
          flex: 0 0 140px;
        }
        img {
          margin-bottom: 20px;
          width: 75%;
        }
        h3 {
          margin: 0;
          text-align: center;
          color: #404040;
          font-size: 22px;
        }
        p {
          text-align: center;
          line-height: 1.7rem;
          font-size: 15px;
        }
      }
      .topps {
        animation: adsd 2s linear;
        @keyframes adsd {
          0% {
            transform: translateY(-230px);
          }
          25% {
            transform: translateY(-150px);
          }
          50% {
            transform: translateY(-100px);
          }
          75% {
            transform: translateY(-50px);
          }
          85% {
            transform: translateY(-30px);
          }
          100% {
            transform: translateY(0px);
          }
        }
      }
      .botm {
        display: flex;
        flex-direction: column;
        justify-content: start;
        animation: adsdd 2s linear;
        @keyframes adsdd {
          0% {
            transform: translateY(130px);
          }
          25% {
            transform: translateY(75px);
          }
          50% {
            transform: translateY(40px);
          }
          75% {
            transform: translateY(15px);
          }
          100% {
            transform: translateY(0px);
          }
        }
      }
      .bottoms {
        width: 32%;
        height: 720px;
        margin-bottom: 30px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        background-color: #f5f5f5;
        padding: 20px 24px;
        box-sizing: border-box;
        .top {
          flex: 0 0 160px;
        }
        img {
          width: 75%;
          margin-bottom: 20px;
        }
        h3 {
          margin: 0;
          text-align: center;
          color: #404040;
          font-size: 22px;
        }
        p {
          text-align: center;
          line-height: 1.7rem;
          font-size: 15px;
        }
      }
    }
    .recognitionsList {
      // display: flex;
      // justify-content: space-between;
      width: 100%;
      // height: 660px;
      font-size: 18px;
      & > div {
        background-color: #f5f5f5;
        padding: 30px;
        width: 100%;
        height: 100%;
        text-align: center;
        .head {
          font-size: 24px;
          font-weight: 700;
          padding: 20px 0;
          letter-spacing: 5px;
          height: 100px;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: calc(50% - 1.2em);
            display: block;
            width: 2.5rem;
            height: 0.5rem;
            border-bottom: 6px solid #43a1d5;
          }
          p {
            font-size: 20px;
            margin: 5px 0 0;
            font-weight: 400;
          }
        }
        .liseadad {
          height: 750px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          line-height: 1.7rem;
          div {
            p:nth-child(2n-1) {
              margin-bottom: 0px;
            }
            p:nth-child(2n) {
              margin-top: 0;
            }
          }
        }
      }
    }
    .recog {
      height: 500px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      .left {
        width: 750px;
        height: 500px;
        img {
          height: 100%;
          width: 100%;
        }
        .v-enter {
          opacity: 0;
          transform: translateX(-100px);
        }
        .v-leave-to {
          opacity: 0;
          transform: translateX(-100px);
        }
        .v-enter-active,
        .v-leave-active {
          transition: all 0.4s linear;
        }
      }
      .right {
        height: 100%;
        width: 430px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .v-enter {
          opacity: 0;
          transform: translateX(100px);
        }
        .v-leave-to {
          opacity: 0;
          transform: translateX(100px);
        }
        .v-enter-active,
        .v-leave-active {
          transition: all 0.4s ease;
        }
        .top {
          background: url("../assets/images/about/recognitions.png") no-repeat;
          background-size: 100% 100%;
          height: 86%;
          width: 100%;
          padding: 20px 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .fas {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 700;
            .head {
              font-size: 32px;
              font-weight: 700;
              padding: 0 0 0 20px;
              height: 163px;
              width: 100%;
              background: #f5f5f5;
              display: flex;
              align-items: center;
            }
            .contentEn {
              color: #707070;
            }
            .contentCn {
              color: #363636;
            }
            p {
              margin-bottom: 0;
            }
          }
        }
        .botom {
          height: 12%;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .pre {
            height: 100%;
            width: 49%;
            background: url("../assets/images/about/pre.png") no-repeat;
            background-size: 100% 100%;
            &:hover {
              cursor: pointer;
            }
          }
          .next {
            height: 100%;
            width: 49%;
            background: url("../assets/images/about/next.png") no-repeat;
            background-size: 100% 100%;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
