<template>
  <div class="he">
    <el-row :gutter="0">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="grid-content bg-purple">
          <div class="content">
            <img src="@/assets/images/logo.png" alt="" @click="jump('')" />
            <div class="nav">
              <div
                :class="{ navs: true, active: flag == '' }"
                @mousemove="move"
                @click="jump('')"
              >
                首页
              </div>
              <div
                :class="{ navs: true, active: flag == 'store' }"
                @click="jump('store')"
              >
                商城
              </div>
              <div
                :class="{ navs: true, active: flag == 'news' }"
                @click="jump('news')"
              >
                新闻资讯
              </div>
              <div
                :class="{ navs: true, active: flag == 'about' }"
                @click="jump('about')"
              >
                关于我们
              </div>
              <div
                :class="{ navs: true, active: flag == 'contact' }"
                @click="jump('contact')"
              >
                联系我们
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: "about",
    };
  },
  created() {},
  mounted() {
    console.log("当前路由", this.$route.path.split("/")[1]);
    this.flag = this.$route.path.split("/")[1];
  },
  computed: {},
  watch: {
    $route: function () {
      this.flag = this.$route.path.split("/")[1];
    },
  },
  methods: {
    jump(e) {
      console.log(e);
      this.flag = e;
      this.$router.push("/" + e);
    },
    move() {},
  },
};
</script>
<style lang="less" scoped>
.he {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 64px;
  z-index: 99;
  background-color: #fff;

  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}
.bg-purple {
  width: 100%;
  height: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      height: 50%;
      &:hover {
        cursor: pointer;
      }
    }
    .nav {
      //   width: 40%;
      height: 100%;
      justify-content: space-around;
      display: flex;
      align-items: center;
      .navs {
        flex-shrink: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 0 30px;
        height: 100%;
        color: #707070;
        &:hover {
          background-color: #c5c5c5;

          cursor: pointer;
        }
      }
      .active {
        // border-bottom: 2px solid #178cceff;
        color: #178cceff;
        font-size: 1.1rem;
      }
    }
  }
}
</style>
