export default {
    a: [
        {
            titile: '1233',
            time: '2021年7月12日',
            breviary: '“我就是一不小心从‘教授’变成了‘贩子’。”美国张氏国际集团董事长张建荣在接受海鲜指南采访时笑道。也是这样一个“贩子”，在今年11月中旬海鲜指南举办的“2016中国海产十大最具影响力年度人物”评选中，经评委和网友投票获选成为2016中国海产十大最具影响力年度人物之一。',
            imgUrl: '',
            content: ''
        }
    ],
    storeIcon: {
        first: [
            {
                id: 'first_1',
                img: require('./images/big1.png'),
                title: '源头直采',
                text: '坚守源头 严格优选'
            },
            {
                id: 'first_2',
                img: require('./images/big2.png'),
                title: '品类丰富',
                text: '优选全球优质海产品 一站式购买'
            },
            {
                id: 'first_3',
                img: require('./images/big3.png'),
                title: '新鲜低价',
                text: '急冻锁鲜 工厂品质'
            },
            {
                id: 'secont_1',
                img: require('./images/big4.png'),
                title: '闪速下单',
                text: '常用清单快速购买'
            },
            {
                id: 'secont_2',
                img: require('./images/big5.png'),
                title: '食品安全',
                text: '严格管控天然健康'
            },
            {
                id: 'secont_3',
                img: require('./images/big6.png'),
                title: '售后服务',
                text: '专业客服 解疑答惑'
            }
        ],
        secont: [

        ]
    },
    services: [
        {
            id: 'services_1',
            img: require('./images/service1.png'),
            head: '帮助中心',
            text1: '促成采购全流程中的常见问题',
            text2: '以及详细解决方法'
        },
        {
            id: 'services_2',
            img: require('./images/service2.png'),
            head: '会员权益',
            text1: '通过消费或充值提升会员等级',
            text2: '可享有不同的福利和特权'
        },
        {
            id: 'services_3',
            img: require('./images/service3.png'),
            head: '售后规则',
            text1: '采购全流程中涉及的商户利益',
            text2: '或者权益说明'
        },
    ],
    contact_location: [
        // {
        //     cn: {
        //         id: 'contact_location1',
        //         head: '',
        //         head2: '美国张氏国际集团美国公司',
        //         location: '地址：美国华盛顿州柯克兰市市场街1611号',
        //         phone: '电话：（206）283-9098',
        //         fax: '传真：（425）576-1245',
        //         postcode: ''
        //     },
        //     en: {
        //         id: 'contact_location_1',
        //         head: '',
        //         head2: 'Chang International, Inc.',
        //         location: 'Add.:1611 Market St., Kirkland,WA 98033, USA',
        //         phone: 'Tel.:（206）283-9098',
        //         fax: 'Fax:（425）576-1245',
        //         postcode: ''
        //     }

        // },
        {
            cn: {
                id: 'contact_location2',
                // head: '美国张氏国际集团中国加工厂',
                head2: '张氏（青岛）海产加工有限公司',
                location: '地址：中国青岛即墨市大信镇天山三路27号',
                phone: '电话：0532-82539999',
                fax: '传真：0532-82530000',
                postcode: '邮编：266229'
            },
            en: {
                id: 'contact_location_2',
                // head: 'Chang International Group - Processing Plants in China',
                head2: 'Chang International (Qingdao) Inc.',
                location: 'Add.:No. 27 Tianshan Third Road, Daxin Town, Jimo, Qingdao, China',
                phone: 'Tel.: 0532-82539999',
                fax: 'Fax: 0532-82530000',
                postcode: 'PC: 266229'
            }
        },
        {
            cn: {
                id: 'contact_location3',
                // head: '美国张氏国际集团中国销售公司',
                head2: '青岛日正商贸有限公司',
                location: '地址：中国青岛东海西路33号滨海花园F1-1D',
                phone: '电话：0532-85728898',
                fax: '传真：0532-85770548',
                postcode: '邮编：266071'
            },
            en: {
                id: 'contact_location_3',
                // head: 'Chang International Group – China Sales Companies',
                head2: 'Qingdao Rizheng Trading Co., Ltd.',
                location: 'Add.: 33 Donghai Road, Binhai Garden, Binfen Ge,F1-1D, Qingdao, China,',
                phone: 'Tel.: 0532-85728898',
                fax: 'Fax: 0532-85770548',
                postcode: 'PC: 266071'
            }
        },
        // {
        //     cn: {
        //         id: 'contact_location4',
        //         head: '',
        //         head2: '青岛日正商贸有限公司北京办公室',
        //         location: '地址：北京市经济开发区荣京东街荣京丽都 A座4层412房',
        //         phone: '电话：010-57408016',
        //         fax: '传真：010-87824408',
        //         postcode: ''
        //     },
        //     en: {
        //         id: 'contact_location_4',
        //         head: '',
        //         head2: 'Qingdao Rizheng Trading Co., Ltd.---Beijing Office',
        //         location: 'Add: Room 412, 4th Floor, Block A, Rongjing Lidu, East Rongjing Street, Economic Development Zone, Beijing.',
        //         phone: 'Tel.: 010-57408016',
        //         fax: 'Fax: 010-87824408',
        //         postcode: ''
        //     }
        // },
        // {
        //     cn: {
        //         id: 'contact_location5',
        //         head: '',
        //         head2: '青岛日正商贸有限公司上海办公室',
        //         location: '地址：上海市浦东新区严家桥1号宏慧音悦湾 2号楼303室',
        //         phone: '电话：021-50873533',
        //         fax: '传真：021-50760939',
        //         postcode: ''
        //     },
        //     en: {
        //         id: 'contact_location_5',
        //         head: '',
        //         head2: 'Qingdao Rizheng Trading Co., Ltd.---Shanghai Office',
        //         location: 'Add: Room 303, Building 2, HongHui YinYue Bay, No.1 Yanjiaqiao, Pudong New Area, Shanghai.                ',
        //         phone: 'Tel.: 021-50873533',
        //         fax: 'Fax: 021-50760939',
        //         postcode: ''
        //     }
        // },
    ],
    helps: {
        account: [
            {
                id: 'account_1',
                title: '如何注册和登录海鲜盛宴商城账户？',
                imgUrl: require('./images/helps/q2.png'),
            },
            {
                id: 'account_2',
                title: '修改经营信息',
                imgUrl: require('./images/helps/q3.png'),
            },

        ],
        order: [
            {
                id: 'order_1',
                title: '如何查找想要的商品？',
                imgUrl: require('./images/helps/q4.png'),
            },
            {
                id: 'order_2',
                title: '如何订购商品？',
                imgUrl: require('./images/helps/q1.png'),
            },


        ]
    },
    homeSwiper: [
        require('./images/homeSwiper/homeSwiper1.jpg'),
        require('./images/homeSwiper/homeSwiper2.jpg'),
        require('./images/homeSwiper/homeSwiper3.jpg'),

    ],
    homeIcon: [
        {
            id: 'homeIcon_1',
            img: require('./images/homeIcon1.png'),
            title: '天然渔场',
            text: '孕育天然蓝色',
            text2: '健康食品',
            enTitle: 'Wild & healthy',
            enText: 'seafood from pristine',
            enText2: 'oceans'
        },
        {
            id: 'homeIcon_2',
            img: require('./images/homeIcon2.png'),
            title: '将源自神秘大海的',
            text: '野生美味呈现在',
            text2: '您的餐桌上',
            enTitle: 'Bring natural delicacies',
            enText: ' from the mysterious oceans to ',
            enText2: 'your dining table'
        },
        {
            id: 'homeIcon_3',
            img: require('./images/homeIcon3.png'),
            title: '富含优质蛋白、',
            text: 'Omega-3不饱和脂肪酸',
            text2: '等多种营养成分',
            enTitle: 'Rich in nutrients such as high quality protein,',
            enText: 'Omega-3 unsaturated fatty acid,',
            enText2: 'and others'
        },
        {
            id: 'homeIcon_4',
            img: require('./images/homeIcon4.png'),
            title: '从捕捞、加工到销售',
            text: '通过全程追溯系统',
            text2: '跟踪产品，保障品质安全',
            enTitle: 'Full traceability system from',
            enText: 'harvest to processing to marketing & sales',
            enText2: 'to ensure product safety.'
        },
        {
            id: 'homeIcon_5',
            img: require('./images/homeIcon5.png'),
            title: '科学系统的捕捞管理体系',
            text: '保证渔业发展和海鲜',
            text2: '来源的持续性',
            enTitle: 'Products from scientifically',
            enText: 'managed fisheries to ensure natural resource',
            enText2: 'sustainability and future development.'
        }
    ],
    lines: [
        {
            id: 'lines_18',
            time: '2019年',
            neir: [
                { cn: '被华润授予“优秀合作伙伴”奖', en: `In 2019, Ocena Gala was awarded the "Excellent Partner" award by China Resources (Holdings) Co., Ltd.` },
            ]
        },
        {
            id: 'lines_17',
            time: '2019年',
            neir: [
                { cn: '荣获MSC “中国可持续海产市场突出贡献奖”', en: `In 2019, Ocena Gala won the MSC "Outstanding Contribution Award for China's Sustainable Seafood Market".` },
            ]
        },

        {
            id: 'lines_16',
            time: '2018年',
            neir: [
                { cn: '荣获CCFA最佳合作伙伴奖', en: `In 2018, Ocena Gala won the CCFA “Best Partner”award.` },
            ]
        },
        {
            id: 'lines_15',
            time: '2018年',
            neir: [
                { cn: '成为上合青岛峰会海产品供应商, 被授予“食品安全保障企业”', en: `In 2018, Ocena Gala became a seafood supplier for the SCO Qingdao Summit and was awarded the "Food Safety & Security Enterprise".` },
            ]
        },
        {
            id: 'lines_14',
            time: '2017年',
            neir: [
                {
                    cn: '荣获“山东省食品行业优秀龙头企业”（青岛市唯一一家水产行业获此殊荣）', en: `In 2017, Ocena Gala was awarded the "Outstanding leading Enterprise of Food Industry in Shandong Province" (The only aquatic industry in Qingdao to win this honor).
                ` },
            ]
        },
        {
            id: 'lines_13',
            time: '2016年',
            neir: [
                { cn: 'Ocean Gala被评为“最受网友喜爱海产品牌”', en: `In 2016, Ocean Gala was rated as "The Most Popular Seafood Brand among netizens".` },
            ]
        },
        {
            id: 'lines_12',
            time: '2016年',
            neir: [
                { cn: '公司获评“最受网友喜爱的十大知名海鲜进口商”', en: `In 2016, Our company was awarded the "Top Ten Famous Seafood Importers most loved by netizens". ` },
            ]
        },
        {
            id: 'lines_11',
            time: '2016年',
            neir: [
                { cn: '公司被评为“十大知名海鲜进口商” 上榜企业', en: `In 2016, Our company was rated as the "Top Ten Famous Seafood Importers" on the list. ` },
            ]
        },
        {
            id: 'lines_10',
            time: '2016年',
            neir: [
                { cn: '公司获评“中国十大最具品牌影响力海产企业”', en: `In 2016, Our company was awarded the "Top Ten most influential Seafood Enterprises in China" ` },
            ]
        },
        {
            id: 'lines_9',
            time: '2015年',
            neir: [
                { cn: '习近平主席首次访问美国，董事长张建荣先生作为在美华侨华人水产界唯一代表受邀出席招待晚宴并与习近平主席及华侨华人代表合影。', en: `In 2015, President Xi Jinping visited USA for the first time. The chairman of the board of directors, Mr. Jerry Chang, as the only representative of the overseas Chinese fishery industry in USA, was invited to attend the reception dinner and took a photo with President Xi Jinping and other representatives of overseas Chinese.` },
            ]
        },
        {
            id: 'lines_8',
            time: '2015年',
            neir: [
                { cn: 'Ocean Gala三文鱼干获青岛名牌产品', en: `In 2015, Ocean Gala's dried salmon won the title of Qingdao's famous brand product.` },
            ]
        },
        {
            id: 'lines_7',
            time: '2014年',
            neir: [
                { cn: '美国张氏国际(Chang International Inc.)在美国华盛顿州被评为“杰出社会领导奖”“Outstanding Business Leadership”', en: 'In 2014, Chang International Inc was awarded "The Outstanding Social Leadership” award & "Outstanding Business Leadership" in Washington State of America.' },
            ]
        },
        {
            id: 'lines_6',
            time: '2012年',
            neir: [
                { cn: '董事长Jerry Chang先生当选全球水产届100位最有权威人士； 美国著名杂志《Seafood Executive》对集团董事长Jerry Chang进行专访 ', en: 'In January 2012 Chairman Mr. Jerry Chang was named one of the Top 100 Global Seafood Executives, and was interviewed by the Seafood Executive magazine.' },
            ]
        },
        {
            id: 'lines_5',
            time: '2012年',
            neir: [
                { cn: '公司获评“青岛市农业产业化重点龙头企业”', en: ' In 2012 Chang International (Qingdao)               Inc. was named as Leading Enterprise in Agriculture Industrialization by the city of Qingdao.' },
            ]
        },
        {
            id: 'lines_4',
            time: '2011年',
            neir: [
                { cn: '张氏（青岛）海产有限公司技术中心荣获“青岛市认定企业技术中心”称号', en: 'In 2011 the technical center at Chang International (Qingdao) was recognized as Certified Technology Center by the city of Qingdao.' },
            ]
        },
        {
            id: 'lines_4.1',
            time: '2011年',
            neir: [
                { cn: '世界水产业权威媒体《IntraFish》多次报道美国张氏集团', en: 'The leading media organization on the global seafood industry, IntraFish, has reported about Chang International multiple times over the years.' },

            ]
        },

        {
            id: 'lines_3',
            time: '2010年9月',
            neir: [
                { cn: '获得西雅图市市长最佳中小企业提名', en: 'In September 21st, 2000, Chang International, Inc. was nominated for the Annual Mayor’s Small Business Awards in Seattle, Washington, USA.' },
            ]
        },
        {
            id: 'lines_2',
            time: '2009年3月',
            neir: [
                { cn: '公司被青岛市评为十强水产加工企业', en: 'In March 2009 Chang International (Qingdao) was named as one of the top 10 seafood processing companies in Qingdao. ' },
            ]
        },
        {
            id: 'lines_1',
            time: '2006年起',
            neir: [
                { cn: '集团董事长Jerry Chang先生被聘为中国海洋大学客座教授', en: 'Since 2006 Chairman Mr. Jerry Chang has been invited as guest professor by Ocean University of China.' },
            ]
        }
    ],
    zzimg: require('./images/zz.jpg'),
    footKuai: [
        { title: '商城', litTitle: [{ name: '业务特性', id: 'business' }, { name: '新手指南', id: 'greenhand' }, { name: '客服服务', id: 'customer_service' }], url: 'Store' },
        { title: '新闻资讯', litTitle: [{ name: '公司新闻', id: 'first' }], url: 'News' },
        { title: '关于我们', litTitle: [{ name: '品牌介绍', id: 'BRAND INTRODUCTION' }, { name: '品牌解析', id: 'OUR BRAND' }, { name: '资源优势', id: 'RRSOURCE ADVANTAGE' }, { name: '销售网络', id: 'THE ASLES NETWORK' }, { name: '企业文化', id: 'CORPORATE CULTURE' }, { name: '企业荣誉', id: 'Recognitions' }, { name: '企业资质和认证', id: 'CORPORATE HONOR AND SOCIAL RESPONSIBILITY' },], url: 'About' },
        { title: '联系我们', litTitle: [{ name: '联系方式', id: 'location' }], url: 'Contact' }
    ],
    recognitions: [
        { leftImg: require('./images/about/asc.png'), rightText: { head: '企业荣誉', contentCn: '被华润授予“优秀合作伙伴”奖', contentEn: 'China Resources awarded "Excellent Partner" award' } },
        { leftImg: require('./images/about/asc.png'), rightText: { head: '企业荣誉', contentCn: '荣获MSC “中国可持续海产市场突出贡献奖”', contentEn: 'MSC Award for Outstanding Contribution to Sustainable Seafood Market in China' } },
        { leftImg: require('./images/about/asc.png'), rightText: { head: '企业荣誉', contentCn: '荣获CCFA最佳合作伙伴奖', contentEn: 'Won the CCFA Best Partner Award' } },
        { leftImg: require('./images/about/asc.png'), rightText: { head: '企业荣誉', contentCn: '上合青岛峰会海产品供应商, 被授予“食品安全保障企业”', contentEn: 'Shanghai Cooperation Qingdao Summit seafood supplier, awarded "Food Safety Guarantee Enterprise"' } },
        { leftImg: require('./images/about/asc.png'), rightText: { head: '企业荣誉', contentCn: '荣获“山东省食品行业优秀龙头企业”', contentEn: 'Won the title of "Outstanding Leading Enterprise in Food Industry of Shandong Province"' } },
        { leftImg: require('./images/about/asc.png'), rightText: { head: '企业荣誉', contentCn: 'Ocean Gala被评为“最受网友喜爱海产品牌”', contentEn: 'Ocean Gala named "Most Popular Seafood Brand"' } },
        { leftImg: require('./images/about/asc.png'), rightText: { head: '企业荣誉', contentCn: '公司获评“最受网友喜爱的十大知名海鲜进口商”、“十大知名海鲜进口商” 上榜企业、“中国十大最具品牌影响力海产企业”', contentEn: 'The company was awarded "Top ten Famous Seafood Importers most Favored by Netizens","Top Ten Famous Seafood Importers", "Top Ten Most Influential Seafood Enterprises in China".' } },
        { leftImg: require('./images/about/asc.png'), rightText: { head: '创始人荣誉', contentCn: '多次入选五年一次的“全球100位海产行业最具影响力高管”榜单', contentEn: `He has been listed in the "World's 100 Most Influential Executives in seafood Industry" every five years for many times` } },
        { leftImg: require('./images/about/asc.png'), rightText: { head: '创始人荣誉', contentCn: '获选“中国海产十大最具影响力年度人物“', contentEn: 'Selected as "Ten Most Influential People of the Year in Chinese Seafood"' } },
        { leftImg: require('./images/about/asc.png'), rightText: { head: '创始人荣誉', contentCn: '习近平主席首次访问美国，作为在美华侨华人水产界唯一代表获邀出席招待晚宴并与习近平主席及华侨华人代表合影。', contentEn: 'President Xi Jinping paid his first visit to the United States. As the only representative of the fishery community of Overseas Chinese in the United States, he was invited to attend the reception dinner and took a photo with president Xi Jinping and representatives of overseas Chinese.' } },
        { leftImg: require('./images/about/asc.png'), rightText: { head: '创始人荣誉', contentCn: '作为全球水产界唯一代表受邀出席在新加坡举办的全球农业投资峰会（Global AgInvesting Asia)，并作了“中国水产业的投资机会”的主题发言，获得了全球农业投资界的高度好评。', contentEn: `As the only representative of the Global aquatic industry, he was invited to attend the Global AgInvesting Asia summit held in Singapore and delivered a keynote speech of "Investment opportunities in China's aquatic industry", which was highly praised by the Global agricultural investment community.` } },
        { leftImg: require('./images/about/asc.png'), rightText: { head: '创始人荣誉', contentCn: '获聘为中国海洋大学客座教授。', contentEn: 'He was appointed as visiting professor of Ocean University of China.' } },
        { leftImg: require('./images/about/asc.png'), rightText: { head: '创始人荣誉', contentCn: '应邀参加了中国乌鲁木齐举办的中国西方经济首脑论坛，同时受到与会的第42届美国总统克林顿先生的接见。', contentEn: 'He was invited to attend the China-Western Economic Summit Forum held in Urumqi, China, and received by the 42nd President of the United States Bill Clinton. ' } },

    ]
}