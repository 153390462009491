<template>
  <div class="home">
    <div class="banner">
      <img src="@/assets/images/homBanner.jpg" alt="" />
      <div class="loop" @click="jump"><i></i><span>海鲜盛宴商城</span></div>
      <!-- <div class="loop2"><i></i><span>0532-82539999</span></div> -->
    </div>
    <div class="content introduce">
      <h1>买海鲜就上<i class="jid"></i></h1>
      <h4>有实力！就有好产品，好品质！</h4>
      <div class="detail">
        <div class="left">
          <div class="topsd">
            <transition>
              <p class="" v-show="!langFlag">
                “ocean gala",
                意为”海鲜盛宴“，自创建至今，秉承”全球顶级海鲜盛宴“的品牌定位，坚持“天然深海、优质野生、全球精选”三大理念，围绕“原生态、真天然、纯野生、富营养、全追溯、可持续”六大品质保障，为人们提供全世界各地的优质高端海鲜产品。目前，已成为美国、加拿大等国的主导海鲜品牌，并已进入欧盟、日本等几十个家和地区的主流市场。2011年开始，“ocean
                gala”启动内销，进驻国内高端超市及五星级酒店，目前“ocean gala”
                已发展成为引领国内高端进口海鲜市场的领先品牌，“ocean
                gala”的产品已成为华润OLE、Costco、盒马、麦德龙等零售终端以及香格里拉、凯悦、迪斯尼等五星级酒店和乐园酒店的畅销产品。线上品牌“鲜元素”也已成为中国电商平台知名的海鲜品牌。
              </p>
            </transition>
            <transition>
              <p v-show="langFlag" class="ens">
                Ocean Gala, like 海鲜盛宴 in Chinese, means Festival from the
                Sea, and that’s our objective behind this brand. Ever since
                established we have positioned this brand for premium quality
                seafood from the world, and faithfully followed the principles
                of “Naturally from Deep Sea”, “Wild & Premium”, and “Carefully
                Selected Globally” for products under this brand. We focus on
                “Natural & Wild, Healthy & Nutritious, Traceable and
                Sustainable” in order to provide top quality seafood to the
                consumers. Since we introduced this brand into the Chinese
                market in 2011, first through high end supermarkets and five
                stars hotels, Ocean Gala has been developed into a leading brand
                for high end imported seafood for the Chinese market, and
                currently Ocean Gala products are popular items at high end
                retailers like Ole', Costco, Hema and Metro, and hotels or
                resorts like Shangri-La, Hyatt Regency and Disney. We have also
                established another popular seafood brand, Fresh Element, for
                online sales.
              </p>
            </transition>
          </div>
          <div class="fotgs">
            <div class="loop" @click="jump">
              <i></i><span>海鲜盛宴商城</span>
            </div>
            <div
              :class="{ changeLan: true, isE: langFlag, isC: !langFlag }"
              @click="changeLan"
            ></div>
          </div>
        </div>
        <div class="right">
          <el-carousel height="325px" arrow="always">
            <el-carousel-item
              v-for="(item, index) in homeSwiper"
              :key="'homeSwiper' + index"
            >
              <img class="swiper" :src="item" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="merit content">
      <div class="titles">
        <div class="head">从海洋到餐桌 坚守健康品质</div>
        <div class="cont"></div>
        <div class="foot">
          From ocean to dining table, stick to healthy quality
        </div>
      </div>
      <div class="details">
        <div class="big" v-for="item in homeIcon" :key="item.id">
          <img :src="item.img" alt="" />
          <span class="first zwsize cn">{{ item.title }}</span>
          <span class="zwsize cn">{{ item.text }}</span>
          <span class="zwsize cn">{{ item.text2 }}</span>
          <span class="first zwsize en">{{ item.enTitle }}</span>
          <span class="zwsize en">{{ item.enText }}</span>
          <span class="zwsize en">{{ item.enText2 }}</span>
        </div>
      </div>
    </div>
    <div class="homeNews">
      <div class="titles">
        <div class="head">新闻资讯</div>
        <div class="cont"></div>
        <div class="foot">News and information</div>
      </div>
      <div class="newList content">
        <div class="first" @click="jumpNewsTop('top')" v-if="top[0]">
          <img class="left" :src="top[0].thumbnail" alt="" />
          <div class="right">
            <h3>
              {{ top[0].title }}
            </h3>
            <span> {{ top[0].createTime.split(" ")[0] }} </span>
            <p class="zwsize">
              {{ top[0].abbreviations }}
            </p>
          </div>
        </div>
        <div v-for="(item, index) in newLists" :key="item.id" v-show="newLists">
          <div class="secont" @click="jumpNews(index)">
            <img class="left" :src="item.thumbnail" alt="" />

            <div class="right">
              <h4>
                {{ item.title }}
              </h4>
              <p>
                {{ item.abbreviations }}
              </p>
              <span> {{ item.createTime.split(" ")[0] }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/assets/a.js";
let { homeSwiper, homeIcon } = config;
import axios from "axios";

export default {
  data() {
    return {
      homeSwiper,
      homeIcon,
      top: [],
      newLists: [],
      langFlag: false,
    };
  },
  created() {},
  mounted() {
    console.log(555, this.top);
    this.getTopData(0, "top");
    this.getData(1, 0);
  },
  computed: {},
  methods: {
    jump() {
      this.$router.push("/store");
    },
    jumpNews(index) {
      this.$router.push(`/newsDetail?type=first&&pageNo=1&&index=${index}`);
    },
    spArr(arr, num) {
      //arr是你要分割的数组，num是以几个为一组
      let newArr = []; //首先创建一个新的空数组。用来存放分割好的数组
      for (let i = 0; i < arr.length; ) {
        //注意：这里与for循环不太一样的是，没有i++
        newArr.push(arr.slice(i, (i += num)));
      }
      return newArr;
    },
    changeLan() {
      this.langFlag = !this.langFlag;
    },
    jumpNewsTop(tur) {
      this.$router.push(
        `/newsDetail?type=${tur}&pageNo=1&pageSize=5&category=0&index=0`
      );
    },
    getData(pageNo, type) {
      axios
        .get(
          `http://101.37.125.135:8080/jeecg-boot/manage-api/v1/news/list?pageNo= ${
            pageNo || 1
          }&pageSize=5&category=${type || 0}&frame=1&top=0`
        )
        .then((res) => {
          this.newLists = res.data.result.records;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTopData(type, list) {
      axios
        .get(
          `http://101.37.125.135:8080/jeecg-boot/manage-api/v1/news/list?pageNo=1&pageSize=5&category=${type}&top=1&frame=1`
        )
        .then((res) => {
          this[list] = res.data.result.records;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="less" >
.home {
  padding-top: 64px;

  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
  .banner {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }
    .loop {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      font-weight: 700;
      left: 16.5%;
      bottom: 28%;
      color: #707070;
      i {
        display: inline-block;
        height: 15px;
        width: 15px;
        background: url(../assets/images/kiu.png) no-repeat;
        background-size: 100% 100%;
        margin-right: 5px;
      }
      width: 130px;
      height: 30px;
      font-size: 16px;
      border-radius: 1.5rem;
      background-color: #fff;
    }
    .loop2 {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 16%;
      bottom: 20%;
      height: calc(3vw);
      font-size: 3vw;
      color: #fff;
      i {
        display: inline-block;
        height: calc(2vw);
        width: 2vw;
        background: url(../assets/images/call.png) no-repeat;
        background-size: 100% 100%;
        margin-right: 15px;
      }
    }
  }
  .introduce {
    margin-top: 80px;
    h1 {
      color: #178cceff;
      margin-bottom: 10px;
      margin-top: 0%;
      letter-spacing: 5px;
      vertical-align: middle;
    }
    .jid {
      display: inline-block;
      margin: 0 0 -15px;
      height: 42px;
      width: 150px;
      background: url("../assets/images/logo.png") no-repeat;
    }
    h4 {
      margin-top: 0;
      letter-spacing: 3px;
      color: #363636;
    }
    .detail {
      display: flex;
      height: 325px;
      margin-bottom: 80px;
      .left {
        width: 840px;
        height: 100%;
        margin-right: 2%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .v-enter {
          opacity: 0;
          transform: translateX(100px);
        }
        .v-leave-to {
          opacity: 0;
          transform: translateX(-100px);
        }
        .v-enter-active,
        .v-leave-active {
          transition: all 0.8s linear;
        }
        .topsd {
          height: 290px;
          display: flex;
          width: 1680px;

          & p {
            text-align: justify;
            margin: 0;
            line-height: 25px;
            font-size: 16px;
            color: #707070;
            width: 840px;
            flex-shrink: 1;
            // overflow-y: scroll;
          }
          .ens {
            line-height: 20px;
            font-size: 16px;
          }
        }
        .fotgs {
          display: flex;
          justify-content: space-between;
          line-height: 36px;
          align-items: center;
          .loop {
            display: flex;
            justify-content: center;
            align-items: center;

            i {
              display: inline-block;
              height: 18px;
              width: 18px;
              background: url(../assets/images/kiu2.png) no-repeat;
              background-size: 100% 100%;
              margin-right: 5px;
            }
            width: 170px;
            border-radius: 1.5rem;
            color: #fff;
            background-color: #178cceff;
          }
          .changeLan {
            margin-right: 20px;
            height: 30px;
            width: 100px;
            border-radius: 1rem;
            box-shadow: 0px 0px 18px rgba(06, 01, 48, 0.2);
          }
          .isC {
            background: url("../assets/images/cn.png") no-repeat;
            background-size: 100% 100%;
          }
          .isE {
            background: url("../assets/images/en.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
      .right {
        width: 500px;
        height: 100%;
        .swiper {
          width: 100%;
          height: 100%;
        }
        .el-carousel__button {
          width: 7px;
          height: 7px;
          border-radius: 50%;
        }
      }
    }
  }
  .merit {
    .titles {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .head {
        font-size: 1.9rem;
        color: #178cceff;
      }
      .cont {
        width: 3%;
        margin-top: 1%;
        margin-bottom: 1%;
        border-bottom: 2px solid #178cceff;
      }
      .foot {
        color: #707070ff;
        font-size: 1.1rem;
        margin-bottom: 30px;
      }
    }
    .details {
      width: 100%;
      display: flex;
      justify-content: space-around;
      // margin-bottom: calc(1200px * 0.05);
      .big {
        width: calc(1200px * 0.19);
        margin: 50px 0;
        height: calc(1200px * 0.23);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #707070ff;
        overflow: hidden;
        .en {
          display: none;
          text-align: center;
          width: 90%;
        }
        &:hover {
          height: calc(1200px * 0.26);
          box-shadow: 0px 0px 6px rgba(23, 140, 206, 0.5);
          img {
            animation: identifier 0.8s linear;
          }
          @keyframes identifier {
            0% {
              transform: translateY(0px);
            }
            25% {
              transform: translateY(20px);
            }
            50% {
              transform: translateY(0px);
            }
            75% {
              transform: translateY(-20px);
            }
            100% {
              transform: translateY(0px);
            }
          }
          .en {
            display: block;
          }
          .cn {
            display: none;
          }
        }
        img {
          width: 70px;
          height: 70px;
          margin-bottom: 3vh;
        }
        span {
          margin: 2px;
        }
      }
    }
  }
  .homeNews {
    background-color: #f8f8f8ff;
    padding: 50px 0;
    .titles {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .head {
        font-size: 1.9rem;
        color: #178cceff;
      }
      .cont {
        width: 3%;
        margin-top: 1%;
        margin-bottom: 1%;
        border-bottom: 2px solid #178cceff;
      }
      .foot {
        color: #707070ff;
        font-size: 1.1rem;
        margin-bottom: 30px;
      }
    }
    .newList {
      .first {
        width: 99.8%;
        height: calc(1200px * 0.34 / 1.68);
        &:hover {
          box-shadow: 0px 0px 6px rgba(23, 140, 206, 0.5);
        }
        display: flex;
        align-items: center;
        .left {
          width: 34%;
          height: 98%;
          virtical-align: middle;
          flex-shrink: 0;
        }
        .right {
          width: 66%;
          height: 98%;

          padding: 0% 2%;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          p {
            text-align: justify;
            color: #707070ff;
            text-overflow: ellipsis;
            width: 100%;
            margin-bottom: 0;
          }
          span {
            color: #707070ff;
          }
        }
      }
      & > div {
        margin-bottom: 3vh;
        .secont {
          width: 99.8%;
          height: calc(1200px * 0.25 / 1.48);
          display: flex;
          align-items: center;

          &:hover {
            box-shadow: 0px 0px 6px rgba(23, 140, 206, 0.5);
          }
          .left {
            width: 25%;
            height: 99%;
            virtical-align: middle;
            flex-shrink: 0;
          }
          .right {
            width: 75%;
            height: 100%;
            padding: 0 2% 1%;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            h4 {
              margin-bottom: 0;
            }
            p {
              text-align: justify;
              color: #707070ff;
            }
            span {
              color: #707070ff;
            }
          }
        }
      }
    }
  }
}
</style>
