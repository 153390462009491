import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Store from '../components/Store.vue'
import Contact from '../components/Contact.vue'
import Help from '../components/Help.vue'
import News from '../components/News.vue'
import NewsDetail from '../components/NewsDetail.vue'
import About from '../components/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/store',
    name: 'Store',
    component: Store
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: NewsDetail
  },
]

const router = new VueRouter({
  routes
})

export default router
