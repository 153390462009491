<template>
  <transition>
    <div class="store">
      <img class="banner" src="@/assets/images/storeBanner.jpg" alt="" />

      <div class="greenhand" ref="greenhand">
        <div class="content">
          <div class="titles">
            <div class="head">新手指南</div>
            <div class="cont"></div>
            <div class="foot">Beginner's Guide</div>
          </div>
          <el-carousel height="calc(1200px / 2.7)" arrow="always">
            <el-carousel-item>
              <div class="shell">
                <img src="@/assets/images/zhinan/zn1.png" alt="" />
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="shell">
                <img src="@/assets/images/zhinan/zn2.png" alt="" />
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="shell">
                <img src="@/assets/images/zhinan/zn3.png" alt="" />
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="shell">
                <img src="@/assets/images/zhinan/zn4.png" alt="" />
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="shell">
                <img src="@/assets/images/zhinan/zn5.png" alt="" />
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="shell">
                <img src="@/assets/images/zhinan/zn6.png" alt="" />
              </div>
            </el-carousel-item>
             <el-carousel-item>
              <div class="shell">
                <img src="@/assets/images/zhinan/zn7.png" alt="" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="content" ref="business">
        <div class="titles business">
          <div class="head">业务特性</div>
          <div class="cont"></div>
          <div class="foot">Business characteristics</div>
        </div>
        <transition>
          <div class="details" ref="wings">
            <div class="big" v-for="item in storeIcon.first" :key="item.id">
              <img :class="{ topps: isInViewport }" :src="item.img" alt="" />
              <span :class="{ first: true, botm: isInViewport }">{{
                item.title
              }}</span>
              <span :class="{ zwsize: true, botm: isInViewport }">{{
                item.text
              }}</span>
            </div>
          </div>
        </transition>
        <!-- <div class="details" ref="wings">
          <div class="big" v-for="item in storeIcon.secont" :key="item.id">
            <img :src="item.img" alt="" />
            <span class="first">{{ item.title }}</span>
            <span class="zwsize">{{ item.text }}</span>
          </div>
        </div> -->
      </div>
      <div class="content customer_service" ref="customer_service">
        <div class="titles">
          <div class="head">客户服务</div>
          <div class="cont"></div>
          <div class="foot">customer service</div>
        </div>
        <div class="detail">
          <div v-for="item in services" :key="item.id" @click="help(item.id)">
            <img :src="item.img" alt="" />
            <p class="first">{{ item.head }}</p>
            <p class="">{{ item.text1 }}</p>
            <p class="">{{ item.text2 }}</p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import config from "@/assets/a.js";
let { storeIcon, services } = config;
export default {
  data() {
    return {
      storeIcon,
      services,
      isInViewport: false,
    };
  },
  created() {
    this.listenerFunction();
  },
  destroyed() {
    this.stopListenerFunction();
  },
  mounted() {
    var a = this.$route.params.jumpRef;
    console.log(444444444, this.$route.params.jumpRef);
    this.$nextTick(
      () =>
        a &&
        window.scrollTo({
          top: this.$refs[a].getBoundingClientRect().top - 70,
          behavior: "smooth",
        })
    );
    // this.$refs[a].scrollIntoView({ block: 'start', behavior: 'smooth' })
  },
  computed: {},
  methods: {
    help(e) {
      e == "services_1" ? this.$router.push("/help") : "";
      console.log(e);
    },

    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    stopListenerFunction() {
      document.removeEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      if (this.hasScrolled) return;
      const topDistance = this.$refs.wings.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const elemHeight = this.$refs.wings.clientHeight;
      this.isInViewport =
        topDistance <= windowHeight / 1.5 && topDistance >= 0 - elemHeight;
      console.log(323, this.isInViewport);

      // this.hasScrolled = true;
    },
  },
};
</script>
<style lang="less" >
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
.store {
  padding-top: 4rem;

  width: 100%;
  overflow-y: scroll;
  .banner {
    width: 100%;
    margin-bottom: 50px;
    min-height: calc(100vw / 3.2);
  }
  .titles {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .head {
      font-size: 1.9rem;
      color: #178cceff;
    }
    .cont {
      width: 3%;
      margin-top: 1%;
      margin-bottom: 1%;
      border-bottom: 2px solid #178cceff;
    }
    .foot {
      color: #707070ff;
      font-size: 1.1rem;
      margin-bottom: 30px;
    }
  }
  .v-enter {
    opacity: 0;
    transform: translateX(-100px);
  }
  .v-leave-to {
    opacity: 0;
    transform: translateX(-100px);
  }
  .v-enter-active,
  .v-leave-active {
    transition: all 0.4s linear;
  }
  .details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 4px;
    .big > .topps {
      animation: adsd 2s linear;
      @keyframes adsd {
        0% {
          transform: translateY(-110px);
        }
        25% {
          transform: translateY(-75px);
        }
        50% {
          transform: translateY(-40px);
        }
        75% {
          transform: translateY(-15px);
        }
        100% {
          transform: translateY(0px);
        }
      }
    }
    .big > .botm {
      animation: adsdd 2s linear;
      @keyframes adsdd {
        0% {
          transform: translateY(110px);
        }
        25% {
          transform: translateY(75px);
        }
        50% {
          transform: translateY(40px);
        }
        75% {
          transform: translateY(15px);
        }
        100% {
          transform: translateY(0px);
        }
      }
    }
    .big {
      &:hover {
        box-shadow: 0px 0px 6px rgba(23, 140, 206, 0.5);
      }
      img {
        width: 70px;
        height: 70px;
        margin-bottom: 3vh;
      }
      width: 20%;
      margin: 0 5%;
      height: 250px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #707070ff;
      .first {
        font-size: 1.2rem;
        color: #363636ff;
        margin-bottom: 5px;
      }
    }
  }
  .greenhand {
    width: 100%;
    // height: 50vh;
    margin-bottom: 50px;
    background-color: #f8f8f8ff;
    & > div {
      padding-top: 30px;
      .el-carousel__button {
        background-color: #707070ff;
      }
      .shell {
        width: 100%;
        height: calc(1200px / 2.78);
        img {
          width: 100%;
          height: calc(1200px / 2.78);
        }
      }
    }
  }
  .customer_service {
    margin-bottom: 5vh;
    .detail {
      height: 320px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      & > div {
        width: 300px;
        height: 100%;
        border: 1px solid #ebebebff;
        &:hover {
          background: #f8f8f8;
          img {
            transform: rotateY(360deg);
          }
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 80px;
          height: 80px;
          margin-bottom: 10px;
          transition: 1s;
        }
        p {
          margin-top: 0;
          line-height: 30px;
          margin-bottom: 0;
          color: #707070;
        }
        .first {
          font-size: 1.1rem;
          font-weight: 550;
        }
      }
    }
  }
}
</style>
