import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.use(ElementUI);
router.beforeEach((to, from, next) => {
  if (navigator.userAgent.match(/(iPhone|iPad|Android|ios)/i)) {
    console.log("移动端");
    const url=location.href.replace('www','m')
    location.replace(url.replace('http','https'))
  } else {
    console.log("PC端");
  }
  /* 路由发生变化修改页面title */
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  console.log(56, to)
  next()
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
