<template>
  <div class="contact">
    <img class="banner" src="@/assets/images/about/callMe.jpg" alt="" />
    <div class="content location" ref="location">
      <div class="outside" v-for="item in contact_location" :key="item.id">
        <div>
          <div class="cn">
            <h4 v-if="item.cn.head" class="hu">{{ item.cn.head }}</h4>
            <h4 v-if="item.cn.head2" class="hut">{{ item.cn.head2 }}</h4>
            <p>{{ item.cn.location }}</p>
            <p>{{ item.cn.phone }}</p>
            <p>{{ item.cn.fax }}</p>
            <p v-if="item.cn.postcode">{{ item.cn.postcode }}</p>
          </div>
          <div class="en">
            <h4 v-if="item.en.head" class="hu">{{ item.en.head }}</h4>
            <h4 v-if="item.en.head2" class="hut">{{ item.en.head2 }}</h4>
            <p>{{ item.en.location }}</p>
            <p>{{ item.en.phone }}</p>
            <p>{{ item.en.fax }}</p>
            <p v-if="item.en.postcode">{{ item.en.postcode }}</p>
          </div>
        </div>
      </div>
      <!-- <div class="outside">
        <img src="@/assets/images/logo-2.png" alt="" />
      </div> -->
    </div>
  </div>
</template>
<script>
import config from "@/assets/a.js";
let { contact_location } = config;
export default {
  data() {
    return { contact_location };
  },
  created() {},
  mounted() {
    var a = this.$route.params.jumpRef;
    // console.log(444444444, this.$route.params.jumpRef,this.$refs[a].getBoundingClientRect().top);
    a &&
      window.scrollTo({
        top: this.$refs[a].getBoundingClientRect().top - 70,
        behavior: "smooth",
      });
  },
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
.contact {
  padding-top: 64px;

  .banner {
    width: 100%;
    min-height: calc(100vw / 3.2);
  }
  .location {
    // display: flex;
    // flex-wrap: wrap;
    align-items: center;
    // justify-content: flex-start;
    margin-bottom: 4vh;
    padding: 10px 2px 0;
    & > .outside {
      width: 100%;
      height: 400px;
      // padding: 40px;
      // padding: 40px;
      margin-bottom: 1%;
      margin-bottom: 10px;
      overflow-y: scroll;
      background-color: #f8f8f8ff;
      &:hover {
        background-color: #fff;
        box-shadow: 0px 0px 6px rgba(23, 140, 206, 0.5);
      }
      &:nth-child(2n) {
        margin-left: 5px;
      }
      &:nth-child(2n-1) {
        margin-right: 5px;
      }
      img {
        margin: calc((520px - (1200px * 0.48 * 0.8 / 6.82)) / 2) 10%;
        vertical-align: center;
        width: 80%;
        height: calc(1200px * 0.48 * 0.8 / 6.82);
      }
      & > div {
        // height: 220px;
        // width: 510px;
        // border: 1px solid red;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin:80px 40px;
        .cn {
          // height: 300px;
          width: 510px;

          margin-bottom: 40px;
        }
        .en {
          width: 510px;
          // height: 300px;
        }
        // margin-top: 40px;
        .hu {
          margin-bottom: 10px;
        }
        .hut {
          margin-bottom: 10px;
          // margin-top: 0px;
        }
        h4 {
          margin-bottom: 0px;
          margin-top: 0;
        }
        p {
          margin-bottom: 0px;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
