<template>
  <div class="foot">
    <div class="head">
      <div class="content text">
        <div class="left">
          <img src="@/assets/images/footLogo.png" alt="" />
        </div>
        <div class="right">
          <!-- <i class="icon"></i>
          <span>0532-82539999</span> -->
        </div>
      </div>
    </div>
    <div class="bot">
      <div class="content">
        <div class="firs">
          <div class="left">
            <!-- <p>
            美国张氏国际国内营销中心青岛日正商贸有限公司&nbsp;&nbsp;<a
              :href="zzimg"
              target="_blank"
              >经营证照</a
            >
          </p>
          <p>中国青岛东海西路33号滨海花园F1-1D&nbsp;&nbsp;0532-82539999</p> -->
            <div class="hjiu" v-for="item in footKuai" :key="item.url">
              <h3>{{ item.title }}</h3>
              <p
                v-for="ijy in item.litTitle"
                :key="ijy.name"
                @click="() => jump(item.url, ijy.id)"
              >
                {{ ijy.name }}
              </p>
            </div>
          </div>
          <div class="right">
            <div>
              <img src="@/assets/images/kfzx.jpg" alt="" />
              <p>客服中心</p>
            </div>
            <div>
              <img src="@/assets/images/ewm.jpg" alt="" />
              <p>微信商城</p>
            </div>
          </div>
        </div>
        <div class="secon">
          <p>
            版权所有 青岛日正商贸有限公司 &nbsp;ICP主体备案号:<a
              href="https://beian.miit.gov.cn"
              >鲁ICP备2022005097号</a
            >
          </p>
          <div class="lid">
            <a href="http://www.cyberpolice.cn/wfjb/" target="_blank"
              ><img src="@/assets/images/police.png" alt=""
            /></a>
            <a href="https://www.12377.cn/node_548446.htm" target="_blank"
              ><img src="@/assets/images/inform.png" alt=""
            /></a>
            <a href="https://www.12377.cn/" target="_blank"
              ><img src="@/assets/images/informcenter.png" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/assets/a.js";
let { zzimg, footKuai } = config;
export default {
  data() {
    return { zzimg, footKuai };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    jump(url, ids) {
      console.log(43, url, ids);
      this.$router.push({name:url,params:{jumpRef:ids}});
    },
  },
};
</script>
<style lang="less" scoped>
.foot {
  width: 100%;
  height: 600px;
  background-color: #178cce;
  background-size: 100% 100%;
  .head {
    border-bottom: 1px solid #047cbfff;
    .text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;
      .left {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        img {
          height: 50px;
        }
      }
      .right {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #fff;
        font-size: 1.7rem;
        height: 50px;
        .icon {
          display: inline-block;
          width: 30px;
          height: 30px;
          background: url(../assets/images/call.png) no-repeat;
          background-size: 100% 100%;
          margin-right: 20px;
        }
      }
    }
  }
  .bot {
    height: 500px;
    padding: 60px 0 20px;
    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .firs {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 250px;
      color: #fff;
      .left {
        width: 60%;
        display: flex;
        justify-content: start;
        align-items: flex-start;
        .hjiu {
          margin-right: 7.5%;
          * {
            margin-top: 0;
          }
          border-right: 2px solid #3697cf;
          h3 {
            font-size: 20px;

            font-weight: 300;
          }
          p {
            margin-bottom: 0.5rem;
            font-size: 16px;
            font-weight: 300;
            color: #fff;
            &:hover {
              cursor: pointer;
              color: aqua;
            }
          }
          width: 20%;
          height: 300px;
        }
      }
      .right {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        div {
          margin: 0 0 0 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 150px;
            height: 150px;
          }
          p {
            margin: 5px 0 0;
            font-size: 16px;
          }
        }
      }
    }
    .secon {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      color: #fff;
      a {
        color: #fff;
        text-decoration: none;
      }
      .lid {
        img {
          margin-right: 20px;
        }
        a:nth-child(3) > img {
          margin-right: 0px;
        }
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
