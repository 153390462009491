<template>
  <div class="news">
    <div class="banner">
      <img ref="liveTop" src="@/assets/images/newsBanner.jpg" alt="" />
    </div>
    <div ref="first" style="height: 1px; width: 1px"></div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- <el-tab-pane label="公司新闻" name="first"> -->
      <div class="newList content">
        <div class="first" v-show="top.length > 0" @click="jumpNewsTop('top')">
          <img
            class="left"
            v-if="top.length > 0"
            :src="top[0].thumbnail"
            alt=""
          />
          <div class="right" v-if="top.length > 0">
            <h3>
              {{ top[0].title }}
            </h3>
            <span> {{ top[0].createTime.split(" ")[0] }} </span>
            <p class="zwsize">
              {{ top[0].abbreviations }}
            </p>
          </div>
        </div>
        <div v-for="(item, index) in newLists" :key="item.id">
          <div class="secont content" @click="jumpNews(index)">
            <img class="left" :src="item.thumbnail" alt="" />

            <div class="right">
              <h4>
                {{ item.title }}
              </h4>
              <p class="zwsize">
                {{ item.abbreviations }}
              </p>
              <span> {{ item.createTime.split(" ")[0] }} </span>
            </div>
          </div>
        </div>
      </div>
      <!-- </el-tab-pane> -->
      <!-- <el-tab-pane label="行业资讯" name="second">
        <div class="inSutList content">
          <div
            class="first"
            v-show="insutTop.length > 0"
            @click="jumpNewsTop('insutTop')"
          >
            <img
              class="left"
              v-if="insutTop.length > 0"
              :src="insutTop[0].thumbnail"
              alt=""
            />
            <div class="right" v-if="insutTop.length > 0">
              <h3>
                {{ insutTop[0].title }}
              </h3>
              <span> {{ insutTop[0].createTime.split(" ")[0] }} </span>
              <p class="zwsize">
                {{ insutTop[0].abbreviations }}
              </p>
            </div>
          </div>
          <div v-for="(item, index) in industryLists" :key="item.id">
            <div class="secont" @click="jumpNews(index)">
              <img class="left" :src="item.thumbnail" alt="" />
              <div class="right">
                <h4>
                  {{ item.title }}
                </h4>
                <p class="zwsize">
                  {{ item.abbreviations }}
                </p>
                <span> {{ item.createTime.split(" ")[0] }} </span>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane> -->
    </el-tabs>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="
          activeName == 'first' ? currentPage : currentIndustryPage
        "
        :page-size="5"
        @current-change="this.currentPageChan"
        :total="activeName == 'first' ? newTotal : industryTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      activeName: "first",
      currentPage: 1,
      currentIndustryPage: 1,
      newLists: [],
      industryLists: [],
      newTotal: 0,
      industryTotal: 0,
      top: [],
      insutTop: [],
      liveTop: 0,
    };
  },
  created() {},
  mounted() {
    // let ij = setInterval(() => {
    //   this.liveTop > 0
    //     ? clearInterval(ij)
    //     : this.geou() && console.log(this.liveTop);
    // }, 1000);
    var a = this.$route.params.jumpRef;
    // console.log(444444444, this.$route.params.jumpRef,this.$refs[a].getBoundingClientRect().top);
    this.$nextTick(() => {
      a &&
        window.scrollTo({
          top: this.$refs[a].getBoundingClientRect().top - 70,
          behavior: "smooth",
        });
    });
    this.getData(1, 0);
    this.getTopData(0, "top");
    this.getTopData(1, "insutTop");
  },
  computed: {},
  methods: {
    geou() {
      this.liveTop = this.$refs.liveTop.offsetHeight;
    },
    handleClick(tab) {
      this.liveTop = this.$refs.liveTop.offsetHeight;

      this.activeName = tab.paneName;
      this.activeName == "first"
        ? this.getData(this.currentPage, 0)
        : this.getData(this.currentIndustryPage, 1);
      window.scrollTo(0, 0);
    },
    currentPageChan(e) {
      this.activeName == "first"
        ? (this.currentPage = e) && this.getData(this.currentPage, 0)
        : (this.currentIndustryPage = e) &&
          this.getData(this.currentIndustryPage, 1);
    },
    jumpNews(index) {
      this.$router.push(
        `/newsDetail?type=${this.activeName}&pageNo=${
          this.activeName == "first"
            ? this.currentPage
            : this.currentIndustryPage
        }&index=${index}`
      );
    },
    jumpNewsTop(tur) {
      this.$router.push(
        `/newsDetail?type=${tur}&pageNo=1&pageSize=5&category=0&index=0`
      );
    },
    spArr(arr, num) {
      //arr是你要分割的数组，num是以几个为一组
      let newArr = []; //首先创建一个新的空数组。用来存放分割好的数组
      for (let i = 0; i < arr.length; ) {
        //注意：这里与for循环不太一样的是，没有i++
        newArr.push(arr.slice(i, (i += num)));
      }
      return newArr;
    },
    getData(pageNo, type) {
      axios
        .get(
          `http://101.37.125.135:8080/jeecg-boot/manage-api/v1/news/list?pageNo= ${
            pageNo || 1
          }&pageSize=5&category=${type || 0}&frame=1&top=0`
        )
        .then((res) => {
          type == 1
            ? (this.industryLists = res.data.result.records) &&
              (this.industryTotal = res.data.result.total)
            : (this.newLists = res.data.result.records) &&
              (this.newTotal = res.data.result.total);
          console.log(99, this.industryLists, this.newLists);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTopData(type, list) {
      axios
        .get(
          `http://101.37.125.135:8080/jeecg-boot/manage-api/v1/news/list?pageNo=1&pageSize=5&category=${type}&top=1&frame=1`
        )
        .then((res) => {
          this[list] = res.data.result.records;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="less" >
.news {
  background-color: #f8f8f8;
  padding-top: 64px;
  padding-bottom: 20px;
  .banner {
    width: 100%;
    min-height: calc(100vw / 3.2);
    margin-bottom: 50px;
    img {
      width: 100%;
      min-height: calc(100vw / 3.2);

      vertical-align: top;
    }
  }
  .el-tabs__nav-wrap::after {
    height: 0px;
  }
  ::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
  .el-tabs {
    position: relative;
  }
  .el-tabs__header {
    position: sticky;
    top: 64px;
    top: 0px \9;

    z-index: 88;
  }
  .el-tabs__nav-scroll {
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
  }
  .el-tabs__nav {
    width: 1200px;
    .is-active {
      color: #409eff !important;
    }
    // padding-top: 30px;
    .el-tabs__item {
      width: 50%;
      text-align: center;
      font-size: 1.5rem;
      color: #707070;
    }
    .el-tabs__active-bar {
      width: 30px !important;
      height: 2.5px;
      margin-left: calc(25% - 25px);
    }
  }
  .newList {
    .first {
      width: 99.8%;
      margin-top: 20px;
      height: calc(1200px * 0.34 / 1.68);
      background-color: #fff;
      &:hover {
        box-shadow: 0px 0px 6px rgba(23, 140, 206, 0.5);
      }
      display: flex;
      .left {
        width: 34%;
        height: 100%;
        virtical-align: middle;
        flex-shrink: 0;
      }
      .right {
        width: 66%;
        height: 100%;

        padding: 0% 2%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        p {
          text-align: justify;
          color: #707070ff;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 4.8;
          font-size: 14px;
          -webkit-line-clamp: 3;
        }
        span {
          color: #707070ff;
        }
      }
    }
    & > div {
      margin-bottom: 3vh;
      .secont {
        width: 99.8%;
        height: calc(1200px * 0.25 / 1.48);
        display: flex;
        &:hover {
          box-shadow: 0px 0px 6px rgba(23, 140, 206, 0.5);
        }
        .left {
          width: 25%;
          height: 200px;
          virtical-align: middle;
          flex-shrink: 0;
        }
        .right {
          width: 75%;
          height: 200px;
          padding: 0 2% 1%;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          h4 {
            margin-bottom: 0;
          }
          p {
            text-align: justify;
            color: #707070ff;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 4.8;
            font-size: 14px;
            -webkit-line-clamp: 3;
          }
          span {
            color: #707070ff;
          }
        }
      }
    }
  }
  .inSutList {
    .first {
      width: 99.8%;
      margin-top: 20px;
      height: calc(1200px * 0.34 / 1.68);
      background-color: #fff;
      &:hover {
        box-shadow: 0px 0px 6px rgba(23, 140, 206, 0.5);
      }
      display: flex;
      .left {
        width: 34%;
        height: 100%;
        virtical-align: middle;
        flex-shrink: 0;
      }
      .right {
        width: 66%;
        height: 100%;

        padding: 0% 2%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        p {
          text-align: justify;
          color: #707070ff;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 4.8;
          font-size: 14px;
          -webkit-line-clamp: 3;
        }
        span {
          color: #707070ff;
        }
      }
    }
    & > div {
      margin-bottom: 3vh;
      .first {
        width: 100%;
        height: calc(1200px * 0.44 / 1.48);

        display: flex;
        .left {
          width: 44%;
          height: 100%;
          virtical-align: middle;
          flex-shrink: 0;
        }
        .right {
          width: 56%;
          height: 100%;

          padding: 2% 2%;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          p {
            text-align: justify;
            color: #707070ff;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 4.8;
            font-size: 14px;
            -webkit-line-clamp: 3;
          }
          span {
            margin-bottom: 6%;
            color: #707070ff;
          }
        }
      }
      .secont {
        width: 100%;
        height: calc(1200px * 0.25 / 1.48);
        display: flex;
        .left {
          width: 25%;
          height: 100%;
          virtical-align: middle;
          flex-shrink: 0;
        }
        .right {
          width: 75%;
          height: 100%;
          padding: 0 2% 1%;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          h4 {
            margin-bottom: 0;
          }
          p {
            text-align: justify;
            color: #707070ff;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 4.8;
            font-size: 14px;
            -webkit-line-clamp: 3;
          }
          span {
            color: #707070ff;
          }
        }
      }
    }
  }
  .pagination {
    margin-top: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
