<template>
  <div id="app">
    <Index></Index>
  </div>
</template>
<script>
import Index from "@/views/Index.vue";

export default {
  components: {
    Index,
  },
};
</script>
<style lang="less">
#app {
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  font-family: "Microsoft YaHei, PingFangSC-Regular,Sans-Serif";
  // font-family: "Sans-Serif";
}
* {
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.content {
  width: 1200px;
  margin: 0 auto;
  overflow-x: scroll;
}
.zwsize {
  font-size: 16px;
  color: #707070;
  line-height: 30px;
}
.xbt {
  color: #363636;
}
</style>
